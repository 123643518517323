import React, {useContext, useEffect, useState} from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from '../components/seo'
import ShowsListItem from '../components/shows-list-item'
import {PlayerContext, SHOW_TYPE} from "../components/context/player-context";
import {secondToTime} from "../components/player";
import YoutubeVideo from "../components/youtube-video";

const ShowTemplate = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const playerContext = useContext(PlayerContext)
  const [_play, _setPlay] = useState(false)
  const { play, setPlay, audio, setEnabled, setContent, content } = playerContext

  const [duration, setDuration] = useState(undefined)

  useEffect(() => {
    if (content.type === SHOW_TYPE && content.description === data.show.title && play) {
      _setPlay(true)
      audio.play()
    } else if (content.type === SHOW_TYPE && content.description === data.show.title && !play) {
      audio.pause()
      _setPlay(false)
    } else {
      _setPlay(false)
    }
  }, [content, play, audio])

  const handleToggle = () => {
    if (audio.src !== window.location.toString().substring(0, window.location.toString().length - 1) + data.show.relationships.audio.localFile.publicURL) {
      audio.src = data.show.relationships.audio.localFile.publicURL
    }
    setContent({ type: SHOW_TYPE, description: data.show.title })
    setPlay(content.description === data.show.title ? !play : true)
    setEnabled(true)
  }

  useEffect(() => {
    if(duration === undefined && data.show.relationships.audio) {
      let showAudio = new Audio(data.show.relationships.audio.localFile.publicURL)
      showAudio.onloadedmetadata = (() => {
        setDuration(secondToTime(showAudio.duration))
      });
    }
  }, [])

  return (
    <Layout>
      <SEO
          title={data.show.title}
          description={data.show.body.value}
      />
      <div className="container content-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={data.show.title}
          />

          <h1 className="title is-1">{data.show.title}</h1>

          <div className="date">
            <span>{data.show.created}</span>
          </div>
        </section>

        <section className="section page-content">
          {data.show.field_video ? (
            <div className="video">
              <div className="video-wrapper">
                <YoutubeVideo url={data.show.field_video.uri}/>
              </div>
            </div>
          ) : (
            <div className="image">
              <div className="image-wrapper">
                <Img
                    fluid={data.show.relationships.image.localFile.childImageSharp.fluid}
                    alt={data.show.title}
                />
              </div>
            </div>
          )}

          <div className="content" dangerouslySetInnerHTML={{__html: data.show.body.value }} />

          {data.show.relationships.audio && (
            <div className="has-text-centered">
              <button onClick={handleToggle} className="button is-rounded is-primary has-icon">
                {_play ? (
                    <>
                      <span className="icon icon-pause-o"></span>
                      <span className="icon-explain">Arréter la lecture</span>
                    </>
                ) : (
                    <>
                      <span className="icon icon-play-o"></span>
                      <span className="icon-explain">Lecture</span>
                    </>
                )}
              </button>
            </div>
          )}
        </section>
      </div>

      <div id="shows-list" className="container shows-list">
        <section className="section is-medium">
          <h2 className="title is-2">Nos autres émissions</h2>
          <div className="columns is-multiline">
            {data.otherShows.edges.map(({ node }, index) => (
              <ShowsListItem node={node} key={index} columnType={4} textLimit={120} cssTruncate={false}/>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ShowTemplate

export const query = graphql`
  query($internalId: Int!, $fieldPoids: Int!) {
    show: nodeEmission(status: { eq: true }, drupal_internal__nid: { eq: $internalId }) {
      title
      body {
        summary,
        value
      }
      field_video {
        uri
      }
      created(formatString: "DD MMMM YYYY", locale: "fr")
      relationships {
        audio : field_audio {
          localFile {
            publicURL
          }
        }
        image: field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 890, maxHeight: 530, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    otherShows: allNodeEmission(
      filter: {
        status: { eq: true }, 
        drupal_internal__nid: { ne: $internalId}
        field_poids: { lte: $fieldPoids }
      }, 
      sort: {fields: field_poids, order: DESC},
      limit: 3
    ) {
      edges {
        node {
          id
          title
          body {
            summary,
            value
          }
          created(formatString: "DD MMMM YYYY", locale: "fr")
          path {
            alias
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 670, maxHeight: 400, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
